<template>
    <div>
        <fr-list-group :title="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.title')">
            <fr-list-item :collapsible="false">
                <div slot="list-item-header" class="media float-left">
                <div class="media-body">
                    <h6>{{ $t("uniSCustomisations.arena2036.dashboard.widgets.accountManager.labelUserCount") }}: {{ this.dataSource.totalCount() }}</h6>
                </div>
                <div class="d-flex align-self-center"></div>
                </div>
            </fr-list-item>
            <fr-list-item :collapsible="true" @hide="onHide" @show="onShow">
                <div slot="list-item-header" class="w-100 mb-3">
                <div class="media-body align-self-center mt-3 mb-3 float-left">
                    <h6>{{ $t("uniSCustomisations.arena2036.dashboard.widgets.accountManager.labelUserList") }}</h6>
                </div>
                <button class="btn btn-outline-primary float-right mb-3"  type="button" :ref="`cancel-${id}`"><i :class="['dx-icon-'+this.buttonKey, 'dx-icon-custom-style', 'image-button']"/></button>
                </div>
                <div slot="list-item-collapse-body" class="flex w-100 m-3 pe-4">
                    <DxDataGrid
                        :allow-column-reordering="true"
                        :data-source="dataSource"
                        :show-borders="true"
                        height="inherit"
                        :show-column-lines="false"
                        :column-auto-width="true"
                        :column-min-width="50"
                        :allow-column-resizung="true"
                        @row-prepared="onRowPrepared"
                        :no-data-text="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.noDataText')"
                    >
                        <DxRemoteOperations
                            :searching="true"
                            :filtering="true"
                            :sorting="true"
                            :paging="true"
                        />
                        <DxMasterDetail
                            :enabled="true"
                            :auto-expand-all="autoExpandAll"
                            template="entity"
                        />
                        <DxColumn
                            data-field="givenName"
                            :caption="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.tableHeaders.firstName').toUpperCase()"
                        />
                        <DxColumn
                            data-field="sn"
                            :caption="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.tableHeaders.sn').toUpperCase()"
                        />
                        <DxColumn
                            data-field="company"
                            :caption="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.tableHeaders.company').toUpperCase()"
                        />
                        <DxColumn
                            data-field="mail"
                            :caption="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.tableHeaders.email').toUpperCase()"
                        />
                        <DxSearchPanel
                            :visible="true"
                            :highlight-case-sensitive="false"
                            :placeholder="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.searchPanel.placeholder')"
                        />
                        <DxPaging
                            :page-size="30"
                        />
                        <DxPager
                            :visible="true"
                            :show-page-size-selector="true"
                            :show-info="true"
                            :show-navigation-buttons="true"
                            :infoText="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.pager.infoText').toString()"
                        />
                        <template #entity="{ data }">
                            <Entity
                                :siamEntityId="data.row.key"
                                :owners="owners"
                                :showOwner="showOwner"
                                :themeColor="themeColor"
                                @editPasswordClicked="editPasswordClicked"
                                @resetPasswordClicked="resetPasswordClicked"
                            />
                        </template>
                    </DxDataGrid>
                    <div v-if="showOpts">
                        <div class="row d-flex mb-3 pb-3">{{ this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.options.title')}}</div>
                        <div class="row clearfix">
                            <DxCheckBox
                            v-model="autoExpandAll"
                            :text="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.options.autoExpandAll')"
                            class="col-2 d-inline-flex"
                            />
                            <DxCheckBox
                                class="col-2 d-inline-flex"
                                v-model="showOwner"
                                :text="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.options.showOwner.text')"
                                :hint="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.options.showOwner.hint')"
                            />
                        </div>
                    </div>
                </div>
            </fr-list-item>
        </fr-list-group>
        <b-modal ref="passwordChangeModal" id="passwordChangeModal" :title="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.modals.passwordChange.title')" @ok.prevent="updatePassword">
            <div class="modal-body">

                {{ this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.modals.passwordChange.textAccountPW') }}
                <form ref="passwordForm" @submit.stop.prevent="updatePassword">
                    <fr-floating-label-input
                        name="passwort"
                        fieldName="currentPassword"
                        type="password"
                        v-validate.initial="'required'"
                        v-model="currentPassword"
                        :label="$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.modals.passwordChange.accountPassword')"
                        :reveal="true"
                        :showErrorState="false" >
                            <ul slot="validationError" class="text-left pl-3" v-if="password!=passwordValidation">
                            <li class="[{'fr-valid': !includes(policyFailures, policy.name)}, 'text-primary fr-policy-list-item']">
                                <small class="text-body">{{ pw_match }}</small>
                            </li>
                        </ul>
                    </fr-floating-label-input>
                    <p>
                        {{ this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.modals.passwordChange.text') }}
                    </p>
                    <fr-policy-password-input
                        policyApi="selfservice/registration"
                        v-model="formValues.password"
                        name="password"
                        ref="policyPasswordInput"
                        v-on:validate="onPasswordValidate"
                    />
                </form>
            </div>
        </b-modal>
        <b-modal class="modal-dialog modal-dialog-centered" ref="passwordResetModal" :title="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.modals.passwordReset.title')" @ok.prevent="sendResetPasswordLink">
            <div class="modal-body">
                {{ this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.modals.passwordReset.text') }}
            </div>
        </b-modal>
    </div>
</template>
<script>

import DxCheckBox from 'devextreme-vue/check-box';
import { DxDataGrid, DxColumn, DxSearchPanel, DxPaging, DxPager, DxLookup, DxRemoteOperations, DxMasterDetail } from 'devextreme-vue/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import ListGroup from '@/components/utils/ListGroup.vue';
import ListItem from '@/components/utils/ListItem.vue';
import Entity from './arena2036/Entity.vue';
import FloatingLabelInput from '@/components/utils/FloatingLabelInput';

import { Component, Vue } from 'vue-property-decorator';
import _ from 'lodash';
import PolicyPasswordInput from '@/components/utils/PolicyPasswordInput.vue';

@Component({
    name: 'DataGrid',
    components: {
        DxCheckBox,
        DxDataGrid,
        DxColumn,
        DxSearchPanel,
        DxPaging,
        DxPager,
        DxLookup,
        DxRemoteOperations,
        DxMasterDetail,
        Entity,
        'fr-list-group': ListGroup,
        'fr-list-item': ListItem,
        'fr-policy-password-input': PolicyPasswordInput,
        'fr-floating-label-input': FloatingLabelInput
    },
    $_veeValidate: {
        validator: 'new'
    }
})
export default class DataGrid extends Vue {
    formValues = {
        newPassword: '',
        accountId: ''
    };

    showOwner = false;
    pageSize = 15;
    pageIndex = 0;
    buttonKey = 'expand';
    owners = [];
    passwordValid = false;
    currentPassword = "";
    themeColor = { 'textColor': 'white', 'headerBackgroundColor': '#ec6707' };
    inputCurrent = 'password';
    showCurrent = true;
    store = new CustomStore({
        key: 'siamEntityId',
        load: (loadOptions) => {
            return this.loadData(loadOptions);
        },
        totalCount: (loadOptions) => {
            return this.loadTotalCount(loadOptions);
        }
    });

    onShow () {
        this.buttonKey = 'collapse';
    }

    get id () {
        return 'accountManager';
    }

    onHide () {
        this.buttonKey = 'expand';
    }

    dataSource = new DataSource({
        store: this.store,
        pageSize: 30,
        searchExpr: ['givenName', 'sn', 'mail', 'company'],
        searchOperation: 'co'
    });

    autoExpandAll = false;
    loadOptions = [];
    get showOpts () {
        if (this.dataSource.totalCount() === 1) {
            this.autoExpandAll = true;
            return false;
        } else {
            this.autoExpandAll = false;
            return true;
        }
    }

    onRowPrepared (rowElement) {
        if (rowElement.rowType === 'header') {
            rowElement.rowElement.style.backgroundColor = this.themeColor.headerBackgroundColor;
            rowElement.rowElement.style.color = this.themeColor.textColor;
        }
    }

    loadData (loadOptions) {
        let params = '';
        const selfServiceInstance = this.getRequestService();
        if (!_.isEmpty(loadOptions['filter'])) {
            params = '?_queryFilter=((/userName sw "ex") and (' + this.translateFilterExpression(loadOptions['filter']) + ')';
        } else {
            params = '?_queryFilter=((/userName sw "ex")';
        }
        params += ')';
        if (!_.isNil(loadOptions['sort'])) {
            let operator = '';
            if (loadOptions['sort'][0]['desc']) {
                operator = '-';
            }
            params += '&_sortKeys=' + operator + loadOptions['sort'][0]['selector'];
        } else {
            params += '&_sortKeys=sn';
        }
        params += '&_fields=givenName,sn,mail,_id,company,siamEntityId&_pageSize=' + this.dataSource.pageSize();
        if (!_.isUndefined(loadOptions.skip)) {
            params += '&_pagedResultsOffset=' + loadOptions.skip;
        }
        return selfServiceInstance.get('/managed/user' + params)
            .then(({ data }) => {
                data.result.forEach((element) => {
                    this.owners.push({
                        displayName: element.givenName + ' ' + element.sn + ', ' + element.company + ' (' + element.mail + ')',
                        siamEntityId: element.siamEntityId,
                        givenName: element.givenName,
                        sn: element.sn,
                        mail: element.mail,
                        company: element.company
                    });
                });
                return data.result;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    loadTotalCount (loadOptions) {
        let params = '';
        const selfServiceInstance = this.$root.getRequestService();
        if (!_.isEmpty(loadOptions['filter'])) {
            params = '?_queryFilter=((/userName sw "ex") and (' + this.$root.translateFilterExpression(loadOptions['filter']) + ')';
        } else {
            params = '?_queryFilter=((/userName sw "ex")';
        }
        params += ')&_sortKeys=sn&_fields=givenName,sn,mail,_id,company,siamEntityId&_pageSize=' + this.dataSource.pageSize() + '&_totalPagedResultsPolicy=EXACT';
        if (!_.isUndefined(loadOptions.skip)) {
            params += '&_pagedResultsOffset=' + loadOptions.skip;
        }
        return selfServiceInstance.get('/managed/user' + params)
            .then(({ data }) => {
                return data.totalPagedResults;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    hideModal () {
        const passwordChangeModal = this.$refs['passwordChangeModal'],
            passwordResetModal = this.$refs['passwordResetModal'];
        passwordChangeModal.hide();
        passwordResetModal.hide();
    }

    sendResetPasswordLink () {
        let selfServiceInstance = this.getRequestService(),
            payload = { 'input': {} };
        selfServiceInstance.post('/selfservice/reset?_action=submitRequirements', payload, {})
            .then((response) => {
                let token = response.data.token;
                payload = {
                    token,
                    'input': {
                        'queryFilter': '_id eq "' + this.formValues.accountId + '"'
                    }
                };
                selfServiceInstance = this.getRequestService();
                selfServiceInstance.post('/selfservice/reset?_action=submitRequirements', payload, {})
                    .then((response) => {
                        this.displayNotification('success', this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.notifications.pwResetEmailSent').toString());
                        this.hideModal();
                    })
                    .catch((error) => {
                        this.displayNotification('error', error.response.data.message);
                    });
            })
            .catch((error) => {
                this.displayNotification('error', error.response.data.message);
            });
        this.hideModal();
    }

    editPasswordClicked (id) {
        const editPasswordModal = this.$refs['passwordChangeModal'];
        this.formValues.accountId = id;
        editPasswordModal.show();
    }

    resetPasswordClicked (id) {
        const resetPasswordModal = this.$refs['passwordResetModal'];
        this.formValues.accountId = id;
        resetPasswordModal.show();
    }
    isValid () {
        /* istanbul ignore next */
        return this.$validator.validateAll();
    }

    onPasswordValidate (valid) {
        this.passwordValid = valid;
    }
    revealCurrent () {
        if (this.inputCurrent === 'password') {
            this.inputCurrent = 'text';
            this.showCurrent = false;
        } else {
            this.inputCurrent = 'password';
            this.showCurrent = true;
        }
    }

    updatePassword () {
        /*
            Updates the password of a given account
        */
        // const passwordInput = this.$refs['policyPasswordInput'];
        const headersL = {
            'X-Requested-With': 'XMLHttpRequest',
            'X-OpenIDM-Reauth-Password': this.encodeRFC5987IfNecessary(this.currentPassword)
        };
        this.isValid()
            .then((valid) => {
                if (valid && this.passwordValid) {
                    const idmService = this.getRequestService({
                            headers: headersL
                        }),
                        payload = [
                            {
                                'operation': 'replace',
                                'field': '/password',
                                'value': this.formValues.password
                            }
                        ];
                    idmService.patch('/managed/user/' + this.formValues.accountId, payload)
                        .then((response) => {
                            this.displayNotification('success', this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.modals.passwordChange.success').toString());
                            this.hideModal();
                        })
                        .catch((error) => {
                            this.displayNotification('error', error.response.data.message);
                        });
                } else {
                    this.displayNotification('error', this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.modals.passwordChange.validationError').toString());
                }
            });
    }
}
</script>
<style lang="scss" scoped>
.image-button {
    font-size: 24px !important;
}
</style>
